<template>
	<div>
		<el-form ref="form" :model="form" label-width="80px">
			<el-form-item label="上传">
				<el-upload
					action="*"
					list-type="picture-card"
					ref="upload"
					:on-preview="handlePictureCardPreview"
					:on-remove="handleRemove"
					:before-upload="handleBeforeUpload"
					:http-request="handleHttpRequest"
					:auto-upload="true"
					accept="image/jpg,image/jpeg,image/png,video/mp4"
					:limit="6"
					:on-exceed="handleExceed"
					multiple
				>
					<div slot="tip" class="el-upload__tip">
						<p class="tips">视频只能上传mp4格式文件<br>最多上传6个文件<br>文件大小不能超过2GB</p>
					</div>
					<template slot="file" slot-scope="{file}">
						<img
							class="el-upload-list__item-thumbnail"
							:src="file.url" alt=""
							v-if="(file.raw.type!=='video/mp4')&&(file.status!=='uploading')"
						>
						<video
							class="el-upload-list__item-thumbnail"
							v-if="(file.raw.type==='video/mp4')&&(file.status!=='uploading')"
							autoplay muted
						>
							<source :src="file.url" type="video/mp4">
						</video>
						<label class="el-upload-list__item-status-label">
							<i class="el-icon-upload-success el-icon-check"></i>
						</label>
						<div class="Up-progress" v-if="file.status === 'uploading'">
							<el-progress
								v-if="file.status === 'uploading'"
								type="circle"
								:stroke-width="6"
								:percentage="file.percentage"
								class="progress"
							>
							</el-progress>
						</div>
						<span class="el-upload-list__item-actions" v-if="file.status!=='uploading'">
              <span class="el-upload-list__item-preview" @click="handlePictureCardPreview(file)">
                <i class="el-icon-zoom-in"></i>
              </span>
              <span class="el-upload-list__item-delete" @click="handleRemove(file)">
                <i class="el-icon-delete"></i>
              </span>
            </span>
					</template>
					<i class="el-icon-plus"></i>
				</el-upload>
				<!--				dialog框是用来预览用的-->
				<el-dialog :visible.sync="dialogAttr.visible" destroy-on-close>
					<img width="100%" :src="dialogAttr.url" alt="" v-if="dialogAttr.fileType!=='video/mp4'">
					<video width="100%" v-if="dialogAttr.fileType==='video/mp4'" controls autoplay>
						<source :src="dialogAttr.url" type="video/mp4">
					</video>
				</el-dialog>
			</el-form-item>
			<el-form-item label="拍摄时间">
				<el-date-picker
					type="date"
					placeholder="选择日期"
					v-model="form.date"
					style="width: 100%;"
					:picker-options="pickerOptions"
					value-format="yyyy-MM-dd"
					format="yyyy年 MM月 dd日"
				></el-date-picker>
			</el-form-item>
<!--			todo 这里的添加附言功能先关闭了-->
			<el-form-item label="附言">
				<el-input v-model="form.postscript"></el-input>
			</el-form-item>
			<el-form-item>
				<el-button
					type="primary"
					@click="onSubmit"
					:disabled="btnStatus.uploadBtn"
				>
					{{ btnStatus.upBtnContent }}
				</el-button>
				<el-button @click="handleAbort" :disabled="btnStatus.cancelBtn">取消上传</el-button>
			</el-form-item>
		</el-form>
	</div>
</template>

<script>
import moment from 'moment'
import autoRefreshRequestMixin from "@/mixins/autoRefreshRequestMixin";
import axios from "axios";
/*注意，el-upload有2种file，一种是上传好以后的file，这个file内有onProgress方法，可以设置进度条
* 而一种在组件的uploadFiles列表内.this.$refs.upload.uploadFiles只用于控制页面空间的状态显示
* ---this.form.fileList[i].file===this.$refs.upload.uploadFiles[i].raw*/
export default {
	name: "FileCom",
	data() {
		return {
			form: {
				date: moment().format('YYYY-MM-DD'),
				postscript: '',
				fileList: []
			},
			pickerOptions: {
				// 日期输入框选项
				disabledDate(time) {
					return time.getTime() > Date.now();
				},
				shortcuts: [{
					text: '今天',
					onClick(picker) {
						picker.$emit('pick', new Date());
					}
				}, {
					text: '昨天',
					onClick(picker) {
						const date = new Date();
						date.setTime(date.getTime() - 3600 * 1000 * 24);
						picker.$emit('pick', date);
					}
				}]
			},
			dialogAttr: {
				url: '',
				visible: false,
				fileType: ''
			},
			// axios请求的取消列表，点击取消按钮就按列表调用取消axios请求
			cancelList: [],
			btnStatus: {
				// 上传按钮,全为uploading或者success时候禁用
				uploadBtn: true,
				// 取消按钮，没有uploading就禁用
				cancelBtn: true,
				upBtnContent: '立即上传'
			},
			uploadedPostScript: '',
		}
	},
	watch: {
		"form.fileList": {
			// 监测fileList的状态,有fail或者ready的,就开启上传按钮,否则禁用
			deep: true,
			handler() {
				let uploadBtnCount = 0
				let cancelBtnCount = 0
				let uploadBtnContentCount = 0
				for (let i = 0; i < this.form.fileList.length; i++) {
					if ((this.form.fileList[i].status === 'fail') ||
						((this.form.fileList[i].status === 'ready'))) {
						uploadBtnCount++
					}
					if (this.form.fileList[i].status === 'uploading') {
						cancelBtnCount++
					}
					if (this.form.fileList[i].status !== 'ready') {
						uploadBtnContentCount++
					}
				}
				if (uploadBtnContentCount > 0) {
					this.btnStatus.upBtnContent = '继续上传'
				} else {
					this.btnStatus.upBtnContent = '立即上传'
				}
				if (uploadBtnCount > 0) {
					this.btnStatus.uploadBtn = false
				} else {
					this.btnStatus.uploadBtn = true
				}
				if (cancelBtnCount > 0) {
					this.btnStatus.cancelBtn = false
				} else {
					this.btnStatus.cancelBtn = true
				}
				if (this.form.fileList.length >= 6) {
					// 监测fileList大于限制上传数以后隐藏添加文件按钮
					document.getElementsByClassName("el-upload el-upload--picture-card")[0].style.display = "none"
				} else {
					setTimeout(() => {
						document.getElementsByClassName("el-upload el-upload--picture-card")[0].style.display = "inline-block"
					}, 1005)
				}
			}
		}
	},
	methods: {
		onSubmit() {
			if (!this.form.fileList) {
				this.$message.warning('请选择文件')
				return
			}
			this.autoRefreshRequest({
				// 先post创建一个拍摄日期的album
				url: 'api/album/',
				method: "POST",
				data: {
					date: this.form.date
				}
			}).catch(() => {
				console.log("相册已存在")
			}).finally(() => {
				this.autoRefreshRequest({
					url: 'api/postscript/',
					method: "POST",
					data: {
						content: this.form.postscript,
						date: this.form.date
					}
				}).then(
					(value) => {
						// 记录下postscript的id
						this.uploadedPostScript = value.data
						for (let i = 0; i < this.form.fileList.length; i++) {
							// 防抖措施,状态为success或者uploading的file,不再上传
							if ((this.form.fileList[i].status === 'success') ||
								(this.form.fileList[i].status === 'uploading')) continue
							// 判断完以后,就将这个file状态置为uploading
							this.form.fileList[i].status = 'uploading'
							this.$refs.upload.uploadFiles[i].status = 'uploading'
							// --------下面为axios请求部分逻辑------------
							let fileObj = this.form.fileList[i].file
							let formData = new FormData()
							formData.append("file", fileObj)
							// formData.append("postscript", this.form.postscript)
							formData.append("date", this.form.date)
							this.autoRefreshRequest({
								url: 'api/file/',
								method: "POST",
								data: formData,
								onUploadProgress: progressEvent => {
									// 设定进度
									let num = parseInt(progressEvent.loaded / progressEvent.total * 100)
									// this.$refs.upload.uploadFiles[i].percentage = num
									this.form.fileList[i].onProgress({percent: num})
									if ((this.form.fileList[i].file.size > 1024 * 1024 * 500) && (num > 99)) {
										// 对于大文件视频,服务器处理缩略图需要时间,发出提示用户不要取消请求
										this.$message.info('服务器处理中,请等待!大约需要5分钟!')
									}
								},
								timeout: 0,
								cancelToken: new axios.CancelToken((c) => {
									// 保存这个axios请求的取消函数列表，用于后面取消请求
									this.cancelList.push(c)
								})
							}).then((value) => {
									/*传输完成后file状态之外success,
										并且调整$refs.upload.uploadFiles[i].status页面状态为success*/
									// this.$message.success(`第${i + 1}个文件上传成功`)
									this.$refs.upload.uploadFiles[i].status = 'success'
									this.form.fileList[i].status = 'success'
									// 为fileList添加response属性,组件销毁前统一提交给MainPage进行页面刷新
									this.form.fileList[i].response = value.data
								}
							).catch((err) => {
								// 逻辑同请求成功
								this.$message.error(`文件上传失败-${err}`)
								this.$refs.upload.uploadFiles[i].status = 'fail'
								this.form.fileList[i].status = 'fail'
							})
						}
					}
				).catch((err) => {
					this.$message.error(err)
				})
			})
		},
		handlePictureCardPreview(file) {
			// 预览框内操作逻辑
			this.dialogAttr.url = file.url;
			this.dialogAttr.visible = true;
			this.dialogAttr.fileType = file.raw.type
		},
		handleHttpRequest(file) {
			/*上传框的默认上传行为,需要开启auto-upload
			每次选择好文件以后就会将file push进List内
			并且添加一个status属性为ready
			*/
			file["status"] = "ready"
			this.form.fileList.push(file)
		},
		handleBeforeUpload(file) {
			// 上传之前校验文件类型
			const isIMAGE = ((file.type === 'image/jpeg')
				|| (file.type === 'image/jpeg')
				|| (file.type === 'image/png')
				|| (file.type === 'video/mp4'))
			if (!isIMAGE) {
				this.$message.error('上传文件只能是图片或者视频格式!')
				return false
			}
			if (file.size / 1024 / 1024 > 2048) {
				this.$message.error('上传的文件必须小于2GB!')
				return false
			}
		},
		handleAbort() {
			while (this.cancelList.length > 0) {
				// 弹出所有cancelFunc并且执行,停止所有axios
				this.cancelList.pop()()
			}
			for (let i = 0; i < this.form.fileList.length; i++) {
				if (this.form.fileList[i].status !== 'success') {
					//fileList状态不是success的文件，上传状态设置为ready，并且将控件显示设置也置为ready
					this.$refs.upload.uploadFiles[i].status = "ready"
					this.form.fileList[i].status = 'ready'
				}
			}
			// 删除已上传的postscript
			this.autoRefreshRequest({
				url: `api/postscript/${this.uploadedPostScript.id}`,
				method: 'DELETE'
			})
		},
		handleRemove(file) {
			// 页面显示的file被移除的时候同步移除fileList内的file
			for (let i = 0; i < this.form.fileList.length; i++) {
				if (this.form.fileList[i].file === file.raw) {
					this.form.fileList.splice(i, 1)
				}
			}
			let uploadFiles = this.$refs.upload.uploadFiles
			for (let i = 0; i < uploadFiles.length; i++) {
				// 也要移除upload内的元素,根据传入的file的url匹配
				if (uploadFiles[i]['url'] === file.url) {
					uploadFiles.splice(i, 1)
				}
			}
		},
		handleExceed() {
			this.$message.warning('一次最多只能上传6个文件')
		},
	},
	beforeDestroy() {
		// 组件销毁前触发emit,数据提交给MainPage让其刷新dateCard
		let uploadedFiles = this.form.fileList.map((value) => {
			// 将success的file组成新数组 emit到MainPage
			if (value.status === 'success') {
				return value.response
			}
		})
		this.$emit('uploaded_file', {uploadedFiles, uploadedPostscript: this.uploadedPostScript})
	},
	mixins: [autoRefreshRequestMixin]
}
</script>

<style scoped>
.el-upload-list__item-thumbnail {
	object-fit: cover;
}

.tips {
	line-height: 130%
}

.Up-progress {
	z-index: 1;
	height: auto;
	width: auto;
	background-color: #dcdfe6;
}

.progress {
	z-index: 2;
}

el-form {
	padding-right: 50px;
}
</style>