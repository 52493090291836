<template>
	<div class="container" v-if="dateList.length!==0">
		<el-dialog
			title="上传文件"
			:visible.sync="fileDialogVisible"
			destroy-on-close
			:fullscreen="isMobile"
		>
			<FileCom v-on:uploaded_file="uploadRefresh"/>
		</el-dialog>
		<el-dialog
			:visible="detailDialogVisible"
			fullscreen
			destroy-on-close
			:show-close="false"
			class="detail-Dialog"
			ref="detailPage"
			@opened="adjustDetailzIndex"
		>
			<div style="display: flex;padding-right: 100px" slot="title" v-if="!isMobile">
				<div class="header-container detail">
					<div style="display: flex;align-items: center">
						<div class="logo">
							<el-page-header @back="closeDetail" content="详情页面">
							</el-page-header>
						</div>
					</div>
				</div>
			</div>
			<div slot="title" v-if="isMobile">
				<el-page-header @back="closeDetail" content="详情页面">
				</el-page-header>
			</div>
			<div class="detail-container">
				<DetailPage
					:date="detailDate"
					v-if="detailDialogVisible"
					v-on:delete_date_card_like="deleteDateCardLike"
					v-on:add_date_card_like="addDateCardLike"
					v-on:add_date_card_comment="addDateCardComment"
					v-on:delete_date_card_comment="deleteDateCardComment"
					v-on:delete_date_card_img="deleteDateCardImg"
					v-on:change_date_card_postscript="changeDateCardPostScript"
					v-on:delete_date_card_postscript="deleteDateCardPostScript"
				></DetailPage>
			</div>
		</el-dialog>
		<div style="display: flex;padding-right: 100px" v-if="!isMobile">
			<div class="header-container">
				<div class="left-info" style="display: flex;align-items: center">
					<div class="logo" v-if="$route.name!=='detailRoute'">
						<img src="../../public/img/logo.gif" alt="" class="logo-gif">
					</div>
				</div>
				<div class="right-info">
					<div class="upload-btn-outer">
						<el-menu class="el-menu-demo" mode="horizontal" @select="openDialog">
							<el-menu-item index="1">上传文件</el-menu-item>
						</el-menu>
					</div>
					<div class="mid-search">
						<el-date-picker
							v-model="detailSearchDate"
							type="date"
							:picker-options="pickerOptions"
							placeholder="选择日期"
							@change="searchDetail"
							value-format="yyyy-MM-dd"
							format="yyyy年 MM月 dd日"
						>
						</el-date-picker>
					</div>
					<div class="right-user" style="color: #494b4d">
						<p>欢迎你,小糯米的</p>
						<el-dropdown @command="dropDownCommend">
					<span
						class="el-dropdown-link"
						style="color: #409EFF"
					>
						{{ currentUser.userprofile }}<i
						class="el-icon-arrow-down el-icon--right"></i></span>
							<el-dropdown-menu slot="dropdown">
								<el-dropdown-item command="change"><i class="el-icon-set-up"> 修改账号密码</i></el-dropdown-item>
								<el-dropdown-item command="logout"><i class="el-icon-setting"> 注销</i></el-dropdown-item>
							</el-dropdown-menu>
						</el-dropdown>
					</div>
				</div>
			</div>
		</div>
		<div class="mobile-header-container" v-if="isMobile">
			<div class="mobile-left-nav">
				<el-button-group>
					<el-button plain size="small" class="mobile-left-btn" icon="el-icon-menu" @click="openDrawer">
					</el-button>
					<el-button plain size="small" class="mobile-left-btn" icon="el-icon-upload2" @click="openDialog">
					</el-button>
				</el-button-group>
			</div>
			<div class="mobile-mid-logo"><img src="../../public/img/logo.gif" alt=""></div>
			<div class="mobile-right-setting">
				<el-dropdown @command="dropDownCommend" trigger="click" class="mobile-right-setting-dropdown">
					<i class="el-icon-s-tools" style="font-size: 15px"></i>
					<el-dropdown-menu slot="dropdown">
						<el-dropdown-item command="change"><i class="el-icon-set-up"> 修改账号密码</i></el-dropdown-item>
						<el-dropdown-item command="logout"><i class="el-icon-setting"> 注销</i></el-dropdown-item>
					</el-dropdown-menu>
				</el-dropdown>
			</div>
		</div>
		<el-drawer
			:visible.sync="drawerVisible"
			v-if="isMobile"
			direction="ltr"
			size="250"
		>
			<div slot="title">
				<div>
					<el-date-picker
						v-model="detailSearchDate"
						type="date"
						:picker-options="pickerOptions"
						placeholder="选择日期"
						@change="searchDetail"
						value-format="yyyy-MM-dd"
						format="yyyy年 MM月 dd日"
						style="width: 150px;"
					>
					</el-date-picker>
				</div>
			</div>
			<NavCom ref="nav">
			</NavCom>
		</el-drawer>
		<div class="common-layout">
			<el-container>
				<el-aside
					width="250px"
					class="aside_container transition-box"
					v-if="!isMobile"
				>
					<NavCom ref="nav">
					</NavCom>
				</el-aside>
				<el-main class="main_container">
					<transition name="el-fade-in-linear">
						<router-view
							ref="album"
							:monthList="monthList"
							:key="$route.params.date"
							@open_detail_page="openDetailPage"
						></router-view>
					</transition>
				</el-main>
			</el-container>
		</div>
		<div class="footer">
			<div class="icp">
				<el-link type="info" href="">Materials provided by ⭕</el-link>
			</div>
			<div class="icp">
				<el-link type="info" href="">联系我们 手机:15801802636 邮箱:263121710@qq.com</el-link>
			</div>
			<div class="icp">
				<el-link type="info" href="https://beian.miit.gov.cn/">
					沪ICP备2022014791号
				</el-link>
			</div>
			<div class="police">
				<img src="../../public/img/备案图标.png" style="float:left; height: 19px;width: 19px"/>
				<el-link
					type="info"
					href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31011402009769">沪公网安备
					31011402009769号
				</el-link>
			</div>
		</div>
	</div>
</template>

<script>
import autoRefreshRequestMixin from "@/mixins/autoRefreshRequestMixin";
import FileCom from "@/components/FileCom";
import NavCom from "@/components/NavCom";
import 'element-ui/lib/theme-chalk/base.css';
import {mapState} from "vuex"
import moment from "moment";
import DetailPage from "@/pages/DetailPage";

export default {
	name: "MainCom",
	mixins: [autoRefreshRequestMixin],
	data() {
		return {
			fileDialogVisible: false,
			detailDialogVisible: false,
			detailDate: '', // 存放传给DetailPage的date值
			detailSearchDate: '', // 存放日期搜索框的值
			pickerOptions: {
				// 日期输入框选项
				disabledDate: (time) => {
					let date = moment(time.getTime()).format('YYYY-MM-DD')
					return this.dateList.indexOf(date) === -1
				},
			},
			drawerVisible: false,
		}
	},
	computed: {
		...mapState(['dateList', 'currentUser']),
		monthList() {
			return Array.from(new Set(this.dateList.map((value) => {
				return value.slice(0, 7)
			})))
		},
		routePath() {
			return this.$route.path
		},
		isMobile() {
			let clientWidth = document.body.clientWidth;
			if (clientWidth > 480) {
				return false
			} else {
				return true
			}
		}
	},
	watch: {
		// 2个监视都是将主页重定向到最新的一个月
		// 第一个监视是当从login登录,或者网页重新加载的时候自动重定向
		// 第二个监视是已有网页退回到主页的时候重定向
		monthList: {
			handler() {
				if (this.$route.path === '/') {
					this.$router.push({
						name: 'albumRoute',
						params: {
							date: "latest"
						}
					})
				}
			}
		},
		routePath: {
			handler() {
				if (this.$route.path === '/') {
					this.$router.push({
						name: 'albumRoute',
						params: {
							date: "latest"
						}
					})
				}
			}
		}
	},
	methods: {
		logOut() {
			this.$store.commit('CLEAR_ALL')
			localStorage.removeItem("auth");
			localStorage.removeItem("refresh");
			sessionStorage.removeItem("auth");
			sessionStorage.removeItem("refresh");
			this.$message.info('已注销')
			this.$router.push('/login')
		},
		dropDownCommend(commend) {
			if (commend === "logout") this.logOut()
			else this.$router.push({name: "changeUserInfo"})
		},
		openDialog() {
			// 打开上传文件的dialog
			this.fileDialogVisible = !this.fileDialogVisible
		},
		openDrawer() {
			// 手机模式下打开侧边导航栏
			this.drawerVisible = !this.drawerVisible
		},
		uploadRefresh(uploadedObj) {
			// 文件上传dialog的回调,将文件上传结果push到对应的date的files列表内,使图片样式动态刷新
			uploadedObj.uploadedFiles.forEach((value) => {
				// 判断,如果这个DateCard已经在页面内被渲染了,就push file数据进去
				if (this.$refs.album.$refs[value.date]) {
					this.$refs.album.$refs[value.date][0].ownDate.files.push(value)
				}
			})
			if (this.$refs.album.$refs[uploadedObj.uploadedPostscript.date]) {
				this.$refs.album.$refs[uploadedObj.uploadedPostscript.date].ownDate.postscripts.push(uploadedObj.uploadedPostscript)
			}
			// 最后刷新Album数据,如果有上传文件了以后自动新建的album,也会刷新页面
			this.$store.dispatch('refreshAlbum')
		},
		searchDetail() {
			// 通过搜索框打开DetailPage
			this.detailDialogVisible = true
			this.detailDate = this.detailSearchDate
			this.detailSearchDate = ''
		},
		openDetailPage(date) {
			// 由DataCard触发,传入date,打开DetailPage
			this.detailDialogVisible = true
			this.detailDate = date
		},
		adjustDetailzIndex() {
			// detailPage打开完成后的回调,调整页面的zindex,使其固定
			this.$refs.detailPage.$el.style.zIndex = 70
			window.document.getElementsByClassName('v-modal')[0].style.zIndex = 69
		},
		closeDetail() {
			// 使detail对应的组件强制刷新,更新点赞和评论信息，由DetailPage触发
			this.detailDialogVisible = false
		},
		// ----- DetailPage事件Func--------
		// 下面5个method由DetailPage触发，用于向DateCard发送操作完毕的数据，使其状态与DetailPage同步
		deleteDateCardLike(index) {
			// 如果不在当前页面的DateCard,就不需要对数据进行操作,切换路由的时候会自动刷新
			if (this.$refs.album.$refs[this.detailDate])
				this.$refs.album.$refs[this.detailDate][0].ownDate.likes.splice(index, 1)
		},
		addDateCardLike(likeObj) {
			if (this.$refs.album.$refs[this.detailDate])
				this.$refs.album.$refs[this.detailDate][0].ownDate.likes.push(likeObj)
		},
		addDateCardComment(comment) {
			if (this.$refs.album.$refs[this.detailDate])
				this.$refs.album.$refs[this.detailDate][0].ownDate.comments.push(comment)
		},
		deleteDateCardComment(id) {
			if (this.$refs.album.$refs[this.detailDate]) {
				let dateCardComments = this.$refs.album.$refs[this.detailDate][0].ownDate.comments
				for (let i = 0; i < dateCardComments.length; i++) {
					if (dateCardComments[i].id === id) {
						dateCardComments.splice(i, 1)
					}
				}
			}
		},
		deleteDateCardImg(id) {
			if (this.$refs.album.$refs[this.detailDate]) {
				let dateCardFiles = this.$refs.album.$refs[this.detailDate][0].ownDate.files
				for (let i = 0; i < dateCardFiles.length; i++) {
					if (dateCardFiles[i].id === id) {
						dateCardFiles.splice(i, 1)
					}
				}
			}
		},
		changeDateCardPostScript(postscript) {
			if (this.$refs.album.$refs[this.detailDate]) {
				let dateCardPostscripts = this.$refs.album.$refs[this.detailDate][0].ownDate.postscripts
				for (let i = 0; i < dateCardPostscripts.length; i++) {
					if (dateCardPostscripts[i].id === postscript.id) {
						dateCardPostscripts[i].content = postscript.content
					}
				}
			}
		},
		deleteDateCardPostScript(postscript) {
			if (this.$refs.album.$refs[this.detailDate]) {
				let dateCardPostscripts = this.$refs.album.$refs[this.detailDate][0].ownDate.postscripts
				for (let i = 0; i < dateCardPostscripts.length; i++) {
					if (dateCardPostscripts[i].id === postscript.id) {
						dateCardPostscripts.splice(i, 1)
					}
				}
			}
		}
		// -------------end------------------
	},
	beforeCreate() {
		this.$store.dispatch('refreshAlbum')
		if (IsAuth) {
			this.$store.dispatch('refreshUser')
		}
	},
	components: {FileCom, NavCom, DetailPage}
}
</script>

<style scoped>

.common-layout {
	margin: 0 50px;
}

.aside_container {
	position: fixed;
	height: 100vh;
	overflow: auto;
	margin-top: 60px;
}

.header-container {
	position: fixed;
	flex: 1;
	width: calc(100% - 100px);
	/*width: auto;*/
	padding: 0 1000px 0 0px;
	overflow: hidden;
	height: 59px;
	display: flex;
	border-bottom: solid 1px #DCDFE6;
	z-index: 50;
	background-color: white;
	/*justify-content: space-between;*/
}

.header-container.detail {
	position: absolute;
}

.left-info {
	background-color: #F5F3F0FF;
	width: 250px;
	display: flex;
	justify-content: center;
}

.header-container.detail .logo {
	border-right: 0;
}

.main_container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-top: 60px;
	/*padding: 30px 0 30px 0;*/
	margin-left: 250px;
}

.album {
	margin-bottom: 50px;
}

.right-user {
	display: flex;
	font-size: 14px;
	align-items: center
}

.logo {
	border-right: 1px solid #eeeeee;
}

.mid-search {
	display: flex;
	align-items: center;
	margin-left: 100px;
}

.detail-container {
	display: flex;
	justify-content: center;
	overflow: hidden;
}

.detail-Dialog {
	z-index: 1999;
}

.mobile-header-container {
	position: fixed;
	flex: 1;
	width: calc(100% - 20px);
	/*width: auto;*/
	padding: 0 10px;
	overflow: hidden;
	height: 39px;
	display: flex;
	border-bottom: solid 1px #DCDFE6;
	z-index: 50;
	background-color: #f5f3f0;
	justify-content: space-between;
	align-items: center
}

.footer {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-bottom: 10px;
	margin-left: 250px;
}

.el-dialog__header {
	padding: 0;
}

.right-info {
	display: flex;
	justify-content: space-between;
	width: calc(100% - 250px);
}

.upload-btn-outer {
	/*margin-left: 3px;*/
	border: solid #DCDFE6 1px;
	border-radius: 10px;
	box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);
}


.logo-gif {
	object-fit: contain;
	height: 59px;
}

.police {
	display: flex;
	justify-content: center;
	align-items: center
}

@media screen and (max-width: 480px) {
	.mobile-left-btn {
		background-color: #f5f3f0;
	}

	.main_container {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		/*margin-top: 60px;*/
		margin: 40px 0 0 0;
		/*padding: 30px 0 30px 0;*/
	}

	.common-layout {
		margin: 0
	}

	.el-dialog__body {
		padding: 3px;
	}

	.detail-container {
		display: flex;
		justify-content: center;
		overflow: visible;
	}

	.footer {
		margin-left: 0px;
	}

	.mobile-right-setting {
		width: 87px;
	}

	.mobile-right-setting-dropdown {
		display: flex;
		justify-content: flex-end;
		padding-right: 5px;
	}
}
</style>