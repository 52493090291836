import {Base64} from "js-base64";

function typeCheck(payloadObj) {
	// 对payload进行类型检查
	return (
		// 2个时间戳可以获取,并且为10位数字,其他3个属性也可以获取,才能返回true
		((payloadObj.exp !== undefined) && (/^\d{10}$/.test(payloadObj.exp.toString()))) &&
		((payloadObj.iat !== undefined) && (/^\d{10}$/.test(payloadObj.iat.toString()))) &&
		(payloadObj.jti !== undefined) &&
		(payloadObj.token_type !== undefined) &&
		(payloadObj.username !== undefined)
	)
}


export default function () {
	// 解码Token Function
	let authPayload, refreshPayload,completeRefreshPayload,completeAuthPayload
	if (localStorage.getItem('refresh')&&localStorage.getItem('auth')){
		completeRefreshPayload = localStorage.getItem('refresh')
		completeAuthPayload = localStorage.getItem('auth')
	}else if (sessionStorage.getItem('refresh')&&sessionStorage.getItem('auth'))
	{
		completeRefreshPayload = sessionStorage.getItem('refresh')
		completeAuthPayload = sessionStorage.getItem('auth')
	}else {
		return {
			refreshPayload: undefined,
			authPayload: undefined
		}
	}
	try {
		// 先获取refresh,如果失败,就直接返回
		refreshPayload = JSON.parse(Base64.decode(completeRefreshPayload.split('.')[1]))
	} catch (err) {
		return {
			refreshPayload: undefined,
			authPayload: undefined
		}
	}
	try {
		// 再获取auth
		authPayload = JSON.parse(Base64.decode(completeAuthPayload.split('.')[1]))
	} catch (err) {
		return {
			refreshPayload,
			authPayload: undefined
		}
	}
	// 对2个payload进行类型检查,不符合格式的设为undifined
	if (!typeCheck(refreshPayload)) {
		// 如果refresh校验失败,全undifined
		refreshPayload = undefined
		authPayload = undefined
	}
	if (!typeCheck(authPayload)) authPayload = undefined
	return {
		refreshPayload,
		authPayload
	}
}
