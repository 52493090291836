<template>
	<div class="container" @keydown.enter="onLogin">
		<div class="pc-container" v-if="!isMobile">
			<div class="pc-login-container">
				<el-collapse-transition>-->
					<div v-show="isShow">
						<el-form :model="form" label-width="50px" class="form_container" label-position="top">
							<el-form-item label="用户名" class="login-label">
								<el-input v-model="form.username"/>
							</el-form-item>
							<el-form-item label="密码" class="login-label">
								<el-input type="password" v-model="form.password"/>
							</el-form-item>
							<el-form-item>
								<el-button type="warning" @click="onLogin" class="login_btn">登录</el-button>
								<el-checkbox v-model="rememberMe" style="margin-left: 15px">记住我</el-checkbox>
							</el-form-item>
							<el-form-item>
								<el-button
									type="info" class="login_btn"
									style="width: 180px;"
									@click="$router.push({name:'registerPage'})"
								>注册账号</el-button>
							</el-form-item>
						</el-form>
					</div>
				</el-collapse-transition>
			</div>
		</div>
		<div v-if="isMobile" class="mobile-login-container">
			<el-collapse-transition>-->
				<div v-show="isShow">
					<el-form :model="form" label-width="50px" class="form_container" label-position="top">
						<el-form-item label="用户名" class="login-label">
							<el-input v-model="form.username"/>
						</el-form-item>
						<el-form-item label="密码" class="login-label">
							<el-input type="password" v-model="form.password"/>
						</el-form-item>
						<el-form-item>
							<el-button type="warning" @click="onLogin" class="login_btn">登录</el-button>
							<el-checkbox v-model="rememberMe" style="margin-left: 30px">记住我</el-checkbox>
						</el-form-item>
						<el-form-item>
							<el-button
								type="info" class="login_btn"
								style="width: 180px;"
								@click="$router.push({name:'registerPage'})"
							>注册账号</el-button>
						</el-form-item>
					</el-form>
				</div>
			</el-collapse-transition>
		</div>
		<div class="footer">
			<div class="icp">
				<el-link type="info" href="">Materials provided by ⭕</el-link>
			</div>
			<div class="icp">
				<el-link type="info" href="">联系我们 手机:15801802636 邮箱:263121710@qq.com</el-link>
			</div>
			<div class="icp">
				<el-link type="info" href="https://beian.miit.gov.cn/">
					沪ICP备2022014791号
				</el-link>
			</div>
			<div class="police">
				<img alt="" src="../../public/img/备案图标.png" style="float:left; height: 19px;width: 19px"/>
				<el-link
					type="info"
					href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31011402009769">沪公网安备
					31011402009769号
				</el-link>
			</div>
		</div>
	</div>
</template>

<script>
// import {BASE_TOKEN_URL} from "@/settings";
import axios from 'axios'

export default {
	name: "LoginCom",
	data() {
		return {
			form: {
				username: '',
				password: ''
			},
			isShow: true,
			rememberMe: true,
		}
	},
	methods: {
		onLogin() {
			this.isShow = !this.isShow
			axios({
				url: BASE_TOKEN_URL,
				method: 'POST',
				data: {
					username: this.form.username,
					password: this.form.password
				}
			}).then(value => {
				this.$message({
					message: '登录成功',
					type: 'success'
				});
				if (this.rememberMe) {
					localStorage.setItem('auth', value.data.access)
					localStorage.setItem('refresh', value.data.refresh)
				} else {
					sessionStorage.setItem('auth', value.data.access)
					sessionStorage.setItem('refresh', value.data.refresh)
				}
				this.$router.push({name: 'rootRoute'})
			}).catch((err) => {
				this.isShow = !this.isShow
				this.$message.error('登录失败')
				console.log(err)
			})
		}
	},
	computed: {
		isMobile() {
			let clientWidth = document.body.clientWidth;
			if (clientWidth > 480) {
				return false
			} else {
				return true
			}
		}
	}
}
</script>

<style scoped>
/*.container {*/
/*	background: url("/public/img/IMG_0382.jpg");*/
/*	background-size: cover;*/
/*}*/

/*.common-layout {*/
/*	margin: 0 100px;*/
/*}*/
.pc-container {
	width: 100%;
	height: 100vh;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	background: url("/public/img/login_background.jpg");
	background-size: cover;
}

.pc-login-container {
	width: 30%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.form_container {
	/*设置内部排列*/
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	/*设置外边框*/
	border: 3px solid #a7b892;
	box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
	border-radius: 10px;
	/*设置尺寸*/
	padding: 20px 0 10px;
	width: 265px;
	height: 360px;
	background-color: #8483b4;
	background-color: rgba(132, 131, 180, 0.8);
}


.login_btn {
	margin-top: 10px;
	width: 100px;
}

.el-button--warning {
	background-color: #ecbd2d;
}

.el-button--info {
	background-color: #8dc9d7;
}

/*.aside_container {*/
/*	display: flex;*/
/*	flex-direction: column;*/
/*	justify-content: center;*/
/*	align-items: center;*/
/*	height: 100vh;*/
/*}*/

/*.main_container {*/
/*	display: flex;*/
/*	flex-direction: column;*/
/*	justify-content: center;*/
/*	align-items: center*/
/*}*/
.footer {
	position: absolute;
	bottom: 0;
	left: 50%;
	margin-left: -177.5px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	color: white;
}

.el-link.el-link--info {
	color: white;
}

@media screen and (max-width: 480px) {
	.common-layout {
		margin: 0;
	}

	.container {
		width: 100%;
		height: 100vh;
		background: url("/public/img/mobile_login_background.jpg");
		background-size: cover;
		display: flex;
		justify-content: center;
		align-items: center
	}
}
</style>

<style>
.login-label .el-form-item__label {
	color: #ecbd2d;
}

.el-checkbox__input.is-checked .el-checkbox__inner {
	background-color: #ecbd2d;
	border-color: #ecbd2d;
}

.el-checkbox__input.is-checked + .el-checkbox__label {
	color: #ecbd2d;
}

.el-checkbox {
	color: white;
}
</style>