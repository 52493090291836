import verifyToken from "../JWT/verifyToken";
import decodeTokenPayload from "../JWT/decodeTokenPayload";
import axios from "axios";
// import {BASE_REFRESH_URL} from "@/settings";

export default function () {
	/*可以预先校验token的公钥部分，检测token完整性与失效时间
	如果公钥检测不过关，自动发送请求刷新，刷新失败则冒泡错误refresh failed
	无法验证私钥错误*/
	return new Promise((resolve, reject) => {
		let payload = verifyToken(decodeTokenPayload())
		if (!payload.refreshPayload) {
			reject('refresh failed')
		} else if (!payload.authPayload) {
			let refreshToken, storageType
			if (localStorage.getItem('refresh')) {
				refreshToken = localStorage.getItem('refresh')
				storageType = 'localStorage'
			} else {
				refreshToken = sessionStorage.getItem('refresh')
				storageType = 'sessionStorage'
			}
			// refresh验证通过,auth不通过,刷新auth
			axios.post(BASE_REFRESH_URL, {
				"refresh": refreshToken
			}).then((response) => {
				// 请求成功,刷新token
				if (storageType === 'localStorage') {
					localStorage.setItem('auth', response.data.access)
				} else {
					sessionStorage.setItem('auth', response.data.access)
				}
				resolve()
			}).catch((err) => {
				// 请求失败，返回登录页面
				// alert('登录过期')
				reject({
					msg: 'refresh failed', err
				})
			})
		} else {
			// 双token全验证通过，Promise-resolve()
			resolve()
		}
	})
}