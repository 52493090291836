import Vue from 'vue'
import App from './App.vue'
import VueRouter from "vue-router";
import Vuex from "vuex";
import store from "@/store";
// 引入element
import {
	Checkbox,
	Aside,
	Backtop,
	Button,
	ButtonGroup,
	Col,
	Collapse,
	CollapseItem,
	Container,
	DatePicker,
	Dialog,
	Dropdown,
	DropdownItem,
	DropdownMenu,
	Form,
	FormItem,
	Image,
	Input,
	Link,
	Main,
	Menu,
	MenuItem,
	MenuItemGroup,
	Message,
	MessageBox,
	Option,
	Progress,
	Row,
	Select,
	Submenu,
	Upload,
	PageHeader,
	Result,
	Drawer,
	Popconfirm
} from 'element-ui'
import router from "@/router";
// -----------Vue内置动画
import 'element-ui/lib/theme-chalk/base.css';
import CollapseTransition from 'element-ui/lib/transitions/collapse-transition';

Vue.component(CollapseTransition.name, CollapseTransition)
// ------------
Vue.prototype.$message = Message
Vue.prototype.$messagebox = MessageBox
Vue.config.productionTip = false
Vue.use(VueRouter)
Vue.use(Vuex)
// 注册element
Vue.use(Container)
Vue.use(Popconfirm)
Vue.use(Dropdown)
Vue.use(Checkbox)
Vue.use(Result)
Vue.use(Drawer)
Vue.use(DropdownItem)
Vue.use(PageHeader)
Vue.use(DropdownMenu)
Vue.use(Menu)
Vue.use(Backtop)
Vue.use(Submenu)
Vue.use(Link)
Vue.use(Option)
Vue.use(Select)
Vue.use(Collapse)
Vue.use(CollapseItem)
Vue.use(ButtonGroup)
Vue.use(Image)
Vue.use(MenuItem)
Vue.use(MenuItemGroup)
Vue.use(Progress)
Vue.use(DatePicker)
Vue.use(Dialog)
Vue.use(Aside)
Vue.use(Form)
Vue.use(Upload)
Vue.use(FormItem)
Vue.use(Row)
Vue.use(Col)
Vue.use(Input)
Vue.use(Button)
Vue.use(Main)
// element注册结束
export default new Vue({
	render: h => h(App),
	router,
	store
}).$mount('#app')