export default {
	computed:{
		hasLiked() {
			for (let i = 0; i < this.ownDate.likes.length; i++) {
				if (this.ownDate.likes[i].like_user.username === this.currentUser.username) {
					return true
				}
			}
			return false
		}
	}
}