<template>
	<div class="menu-container" v-if="dateList">
		<el-menu
			:default-active="$route.path"
			class="el-menu-vertical-demo"
			style="width: 200px; height: 100vh"
			unique-opened="unique-opened"
			:default-openeds="[openYear]"
			router
		>
			<el-submenu v-for="(year,index) in yearList" :key="index" :index="year">
				<span slot="title">
					<i class="el-icon-date"></i>
					{{ year }}年
				</span>
				<el-menu-item-group>
					<template slot="title">月份</template>
					<el-menu-item
						v-for="(month,indexM) in monthObj[year]"
						:index="`/date/${month}`"
						:key="indexM">
						<i :class="icon_list[Math.floor(Math.random()*30)]"></i>
						{{ month.split('-')[1] }}月
					</el-menu-item>
				</el-menu-item-group>
			</el-submenu>
		</el-menu>
	</div>
</template>

<script>
import {mapState} from "vuex";

export default {
	name: "NavCom",
	data() {
		return {
			icon_list: ['el-icon-dish',
				'el-icon-dish-1',
				'el-icon-food',
				'el-icon-chicken',
				'el-icon-fork-spoon',
				'el-icon-knife-fork',
				'el-icon-burger',
				'el-icon-tableware',
				'el-icon-sugar',
				'el-icon-dessert',
				'el-icon-ice-cream',
				'el-icon-hot-water',
				'el-icon-water-cup',
				'el-icon-coffee-cup',
				'el-icon-cold-drink',
				'el-icon-refrigerator',
				'el-icon-grape',
				'el-icon-watermelon',
				'el-icon-cherry',
				'el-icon-apple',
				'el-icon-pear',
				'el-icon-orange',
				'el-icon-coffee',
				'el-icon-ice-tea',
				'el-icon-ice-drink',
				'el-icon-milk-tea',
				'el-icon-potato-strips',
				'el-icon-lollipop',
				'el-icon-ice-cream-square',
				'el-icon-ice-cream-round'],
		}
	},
	computed: {
		openYear() {
			// 根据当前路由路径判断打开哪个年份(一级标题)
			if(this.$route.params.date === 'latest'){
				return this.yearList[0]
			}else {
				return this.$route.path.split('-')[0].slice(-4)
			}
		},
		yearList() {
			// 生成年份列表,一级标题使用
			// date切剩年份,然后集合消除多余的部分,再转回列表
			return Array.from(new Set(this.dateList.map((value) => {
				return value.slice(0, 4)
			})))
		},
		monthObj() {
			// 生成月份
			let monthObj = {}
			for (const year of this.yearList) {
				monthObj[year] = []
			}
			let monthList = new Set(this.dateList.map((value) => {
				return value.slice(0, 7)
			}))
			for (const monthListElement of monthList) {
				monthObj[monthListElement.slice(0, 4)].push(monthListElement)
			}
			return monthObj
		},
		...mapState(['dateList'])
	},
	props:['collapse']
}
</script>

<style scoped>

</style>