import autoRefreshAxios from "./autoRefreshAxios";
import vm from '@/main'

export default (options) => {
	return new Promise((resolve, reject) => {
		autoRefreshAxios(options).then(value => {
			resolve(value)
		}).catch(err => {
			// refresh failed来自于axios拦截器的自动刷新token失效后返回的错误 （浏览器端校验公钥失效）
			// err.message.response.statusText 来自于服务器对axios请求发送的token校验失败，（私钥错误）
			if ((err.msg === 'refresh failed') || (err.response["statusText"] === 'Unauthorized')) {
				// 如果错误信息是验证过期,直接跳转登录页面
				vm.$router.push({name: 'loginRoute'})
				vm.$message.error('验证已过期,请重新登录')
				reject('refresh failed')
			} else if (err.message === 'canceled') {
				// 如果请求被取消,发送消息
				vm.$message.warning(`请求取消`)
				reject({msg: 'request canceled'})
			} else {
				// 如果其他错误信息，直接冒泡
				reject(err)
			}
		})
	})
}