<template>
	<div v-if="currentUser" class="main-container">
		<div class="inner-main-container">
			<el-form :model="form" :rules="rules" ref="userForm" class="form-main">
				<el-form-item><h2>修改用户信息</h2></el-form-item>
				<el-form-item label="新用户名">
					<el-input
						v-model="form.new_username"
						placeholder="输入新用户名 不输入则不更改"
					></el-input>
				</el-form-item>
				<el-form-item label="旧密码">
					<el-input
						v-model="form.old_password"
						placeholder="请输入旧密码"
						type="password"
					></el-input>
				</el-form-item>
				<el-form-item label="新密码" prop="password1">
					<el-input
						v-model="form.new_password1"
						placeholder="请输入新密码"
						type="password"
					></el-input>
				</el-form-item>
				<el-form-item label="确认密码" prop="password2">
					<el-input
						v-model="form.new_password2"
						placeholder="请确认新密码"
						type="password"
					></el-input>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="submit">提交</el-button>
					<el-button @click="$router.back()">取消</el-button>
				</el-form-item>
			</el-form>
		</div>
	</div>
</template>

<script>
import {mapState} from "vuex";
import autoRefreshRequestMixin from "@/mixins/autoRefreshRequestMixin";

export default {
	name: "ChangeUserInfo",
	data() {
		let password1Validator = (rule, value, callback) => {
			if (this.form.new_password1 === '') {
				callback(new Error('请输入密码'))
			} else {
				callback()
			}
		}
		let password2Validator = (rule, value, callback) => {
			if (this.form.new_password2 === '') {
				callback(new Error('请再次输入密码'))
			} else if (this.form.new_password2 !== this.form.new_password1) {
				callback(new Error('两次密码输入不一致'))
			} else {
				callback()
			}
		}
		return {
			form: {
				new_username: "",
				old_password: "",
				new_password1: "",
				new_password2: "",
			},
			rules: {
				password1: [
					{validator: password1Validator, trigger: 'blur'}
				],
				password2: [
					{validator: password2Validator, trigger: 'blur'}
				],
			}
		}
	},
	computed: {
		...mapState(['currentUser']),
	},
	methods: {
		submit() {
			console.log(11)
			this.$refs.userForm.validate().then((validate) => {
				if (!validate) {
					return Promise.reject("Validate Error")
				} else {
					return this.autoRefreshRequest({
						url: "/login/change_user_info",
						data: this.form,
						method: "POST"
					})
				}
			}).then(() => {
				this.$message.success("修改成功,请重新登录")
				this.$router.push({name: "loginRoute"})
			})
			.catch((err) => {
				if (err === "Validate Error") {
					// pass
				} else if (err.response.status === 400) {
					this.$message.warning("密码错误,请重试")
				} else {
					this.$message.warning(err)
				}
			})
		}
	},
	mixins: [autoRefreshRequestMixin]
}
</script>

<style scoped>
.main-container {
	display: flex;
	width: 100%;
	height: 100vh;
	justify-content: center;
	align-items: stretch;
	flex-direction: column;
}
.form-main {
	top: 0; left: 0; right: 0; bottom: 0; margin: auto;
	padding: 10px;
	max-width: 500px;
	min-width: 300px;
}
</style>