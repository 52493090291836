import VueRouter from "vue-router";
import LoginCom from "@/pages/LoginPage";
import MainCom from "@/pages/MainPage";
import AlbumCom from "@/pages/AlbumPage";
import validateRefresh from "@/axios/validateRefresh";
import { Message } from 'element-ui'
import NotFound from "@/pages/NotFound";
import ChangeUserInfo from "@/pages/ChangeUserInfo";
import RegisterPage from "@/pages/RegisterPage";
//创建并暴露一个路由器
const router = new VueRouter({
	routes: [
		{
			path: '/change',
			name: 'changeUserInfo',
			component: ChangeUserInfo,
		},
		{
			path: '/register',
			name: 'registerPage',
			component: RegisterPage,
			meta: {isOpen: true}
		},
		{
			path: '/login',
			name: 'loginRoute',
			component: LoginCom,
			meta: {isOpen: true}
		},
		{
			path: '/',
			name: 'rootRoute',
			component: MainCom,
			children: [
				{
					path:'date/:date',
					name:'albumRoute',
					component:AlbumCom,
				}
			]
		},
		{
			path: '/404',
			name: 'notFound',
			meta: {
				title: 'Page not found',
			},
			component:NotFound
		},
		{
			path:'/',
			redirect:'/date/new'
		},
		{
			path: '*',
			redirect: '/404'
		}
	],
	scrollBehavior () {
		return { x: 0, y: 0 }
	}
})

router.beforeEach((to, from, next) => {
	if(IsAuth){
		// 每次路由跳转前都验证是否登录
		if (to.meta.isOpen) {
			next()
		} else {
			validateRefresh()
			.then(() => {
				next()
			}).catch(() => {
				Message({
					message:'请先登录',
					center:true,
					type:'warning'
				})
				next('/login')
			})
		}
	}else {
		next()
	}
})
export default router