<template>
	<div v-if="startRender&&ownDate.files.length!==0" class="main-container">
		<div class="time-title" style="">
			<!--			日期与岁数-->
			<h1>{{ childAge }}</h1>
			<h3 style="margin: 10px">{{ dateFormat }}</h3>
		</div>
		<div class="date-card">
			<div class="album-container" v-if="ownDate.files.length>=4">
				<div class="img-container left four-three">
					<own-image
						:src="ownDate.files[0].preview_path"
						fit="cover"
						:preview-src-list="positionOriPath(0)"
						lazy
						:id="ownDate.files[0].id"
						:ref="ownDate.files[0].id"
						v-on:delete="deleteImg"
					></own-image>
				</div>
				<div
					class="img-container right four-three"
				>
					<own-image
						v-for="(file,index) in ownDate.files.slice(1,4)"
						:key="index"
						:src="file.preview_path"
						fit="cover"
						style="padding: 1px"
						:preview-src-list="positionOriPath(index+1)"
						lazy
						:id="file.id"
						:ref="file.id"
						v-on:delete="deleteImg"
					></own-image>
				</div>
			</div>
			<div class="album-container" v-if="ownDate.files.length===3">
				<div class="img-container left four-three">
					<own-image
						:src="ownDate.files[0].preview_path"
						fit="cover"
						:preview-src-list="positionOriPath(0)"
						lazy
						:id="ownDate.files[0].id"
						:ref="ownDate.files[0].id"
						v-on:delete="deleteImg"
					></own-image>
				</div>
				<div class="img-container right four-three">
					<own-image
						v-for="(file,index) in ownDate.files.slice(1,3)"
						:key="index"
						:src="file.preview_path"
						fit="cover"
						style="padding: 1px 0; height: 350px"
						:preview-src-list="positionOriPath(index+1)"
						lazy
						:id="file.id"
						:ref="file.id"
						v-on:delete="deleteImg"
					></own-image>
				</div>
			</div>
			<div class="album-container" v-if="ownDate.files.length===2">
				<div class="img-container left">
					<own-image
						class="left"
						:src="ownDate.files[0].preview_path"
						fit="cover"
						:preview-src-list="positionOriPath(0)"
						lazy
						:id="ownDate.files[0].id"
						:ref="ownDate.files[0].id"
						v-on:delete="deleteImg"
					></own-image>
				</div>
				<div class="img-container right">
					<own-image
						:src="ownDate.files[1].preview_path"
						fit="cover"
						class="right"
						:preview-src-list="positionOriPath(1)"
						lazy
						:id="ownDate.files[1].id"
						:ref="ownDate.files[1].id"
						v-on:delete="deleteImg"
					></own-image>
				</div>
			</div>
			<div class="album-container one" v-if="ownDate.files.length===1">
				<div class="img-container one">
					<own-image
						:src="ownDate.files[0].preview_path"
						fit="cover"
						class="img-one"
						:preview-src-list="positionOriPath(0)"
						lazy
						:id="ownDate.files[0].id"
						:ref="ownDate.files[0].id"
						v-on:delete="deleteImg"
					></own-image>
				</div>
			</div>
<!--			todo 这里评论功能先关闭了-->
			<div class="postscripts">
				<div
					class="postscript"
					v-for="postscript in ownDate.postscripts"
					:key="postscript.id"
					v-if="postscript.content!==''"
				>
					<i class="el-icon-paperclip" style="margin-right: 3px"></i>
					<p style="color: #606266">{{ postscript.upload_user.userprofile }}:</p>
					<p>{{ postscript.content }}</p>
				</div>
			</div>
			<div class="footer">
				<el-button-group>
					<el-button @click="detail=!detail" style="padding: 10px 20px">
						<i class="el-icon-tickets" style="font-size: 15px"></i>
						{{ ownDate.comments.length }}
					</el-button>
					<el-button v-if="hasLiked" @click="changeLike" style="padding: 10px 20px" type="danger" plain>
						<FullHeart fontSize="13px"/>
						{{ ownDate.likes.length }}
					</el-button>
					<el-button v-if="!hasLiked" @click="changeLike" style="padding: 10px 20px">
						<EmptyHeart fontSize="13px"/>
						{{ ownDate.likes.length }}
					</el-button>
				</el-button-group>
				<div class="file_number" @click="goDetailPage">
					<p style="margin: 0 5px" v-if="vidNumber>0">视频 {{ vidNumber }}</p>
					<p style="margin: 0 5px" v-if="picNumber>0">图片 {{ picNumber }}</p>
					<i class="el-icon-more"></i>
				</div>
			</div>
			<el-collapse-transition>
				<DateDetail
					:ownDate="ownDate"
					v-on:change_like="changeLike"
					v-on:send_comment="sendComment"
					v-on:delete_comment="deleteComment"
					v-if="detail"></DateDetail>
			</el-collapse-transition>
		</div>
	</div>
</template>

<script>
import autoRefreshRequestMixin from "@/mixins/autoRefreshRequestMixin";
import ownImage from "@/ownElement/src/ownImage";
import {mapState} from "vuex";
import DateDetail from "@/components/DateDetail";
import FullHeart from "@/components/FullHeart";
import EmptyHeart from "@/components/EmptyHeart";
import dateProcessMixin from "@/mixins/dateProcessMixin";
import permessionCheckMixin from "@/mixins/permessionCheckMixin";
import hasLikedMixin from "@/mixins/hasLikedMixin";
import autoRefreshRequest from "@/axios/autoRefreshRequest";

export default {
	name: "DateCard",
	props: ["date"],
	data() {
		return {
			detail: false,
			ownDate: {},
			startRender: false,
			likeIsChanging: false,
			commentIsDeleting: false,
		}
	},
	computed: {
		oriPathList() {
			// 原图路径，预览图用
			return this.ownDate.files.map((i) => {
				return i.ori_path
			})
		},
		vidNumber() {
			let videoNumber = 0
			this.ownDate.files.map((i) => {
				if (!i.file_type) videoNumber++
			})
			return videoNumber
		},
		picNumber() {
			let videoNumber = 0
			this.ownDate.files.map((i) => {
				if (i.file_type) videoNumber++
			})
			return videoNumber
		},
		hasLiked() {
			for (let i = 0; i < this.ownDate.likes.length; i++) {
				if (this.ownDate.likes[i].like_user.username === this.currentUser.username) {
					return true
				}
			}
			return false
		},
		...mapState(['userExchange', "currentUser"]),
	},
	methods: {
		positionOriPath(index) {
			// 生成大图浏览的列表,使每个预览图位置点开都是当前预览图的大图
			if (this.ownDate.files.length >= 4) {
				return [...this.oriPathList.slice(index, 4), ...this.oriPathList.slice(0, index)]
			} else {
				return [...this.oriPathList.slice(index), ...this.oriPathList.slice(0, index)]
			}
		},
		goDetailPage() {
			// 使用父组件-Album的open_detail事件,从MainPage内打开Detail的El-dialog
			this.$parent.$emit('open_detail_page', this.date)
		},
		changeLike() {
			if (this.likeIsChanging) return;
			for (const index in this.ownDate.likes) {
				if (this.ownDate.likes[index].like_user.username === this.currentUser.username) {
					this.likeIsChanging = true
					// 如果相等,说明已经点赞,进入取消逻辑
					this.autoRefreshRequest({
						url: `api/thumbup/${this.ownDate.likes[index].id}/`,
						method: "DELETE"
					}).then(() => {
						this.likeIsChanging = false
						this.ownDate.likes.splice(index, 1)
						// this.$forceUpdate()
					}).catch((err) => {
						this.$message.error(err)
						this.likeIsChanging = false
					})
					return
				}
			}
			// 如果循环结束函数没有返回,则说明未在likes列表中,进入添加逻辑
			this.likeIsChanging = true
			this.autoRefreshRequest({
				url: 'api/thumbup/',
				method: "POST",
				data: {
					date: this.ownDate.date,
					like_user: this.currentUser.username
				}
			}).then(value => {
				let likeObj = {
					id: value.data.id,
					like_user: {
						username: value.data.like_user,
						userprofile: this.userExchange.name_profile[value.data.like_user]
					}
				}
				this.ownDate.likes.push(likeObj)
				// this.$forceUpdate()
				this.likeIsChanging = false
			}).catch((err) => {
				this.$message.error(err)
				this.likeIsChanging = false
			})
		},
		sendComment(comment) {
			// 接收子组件的emit发送的事件
			this.autoRefreshRequest({
				url: 'api/comment/',
				method: "POST",
				data: {
					"from_user": this.currentUser.username,
					"content": comment.content,
					"to_user": comment.to_user.username,
					"date": this.ownDate.date
				}
			}).then(value => {
				value.data.from_user = {
					username: value.data.from_user,
					userprofile: this.userExchange.name_profile[value.data.from_user]
				}
				if (value.data.to_user) {
					value.data.to_user = {
						username: value.data.to_user,
						userprofile: this.userExchange.name_profile[value.data.to_user]
					}
				}
				this.ownDate.comments.push(value.data)
				// this.$forceUpdate()
			}, error => {
				this.$message.error(error)
			})
		},
		deleteComment(comment) {
			if (this.commentIsDeleting === true) return;
			this.commentIsDeleting = true
			if (!this.permissionCheck(comment.from_user)) {
				this.$message.error('权限不足')
				return
			}
			this.autoRefreshRequest({
				url: `api/comment/${comment.id}/`,
				method: "DELETE"
			}).then(() => {
				// 删除本地数据,实现页面动态刷新
				for (let i = 0; i < this.ownDate.comments.length; i++) {
					if (this.ownDate.comments[i].id === comment.id) {
						this.ownDate.comments.splice(i, 1)
					}
				}
				this.commentIsDeleting = false
			}).catch((err) => {
				this.commentIsDeleting = false
				this.$message.error(err)
			})
		},
		deleteImg(id) {
			let index
			for (let i = 0; i < this.ownDate.files.length; i++) {
				if (this.ownDate.files[i].id === id) {
					if (!this.permissionCheck(this.ownDate.files[i].upload_user)) {
						this.$message.error('只有发布者可以删除这张图片!')
						return
					} else {
						index = i
						break
					}
				}
			}
			autoRefreshRequest({
				url: `api/file/${id}`,
				method: "DELETE"
			}).then(() => {
				// 请求成功后删除ownDate的file
				// 调用对应Image组件的preview组件的hide方法,先将其关闭再修改页面数据,不然会卡住
				this.$refs[id].$refs.previewer.hide()
				this.$nextTick(() => {
					this.ownDate.files.splice(index, 1)
				})
			}).catch((err) => {
				this.$message.error(err)
			})
		}
	},
	components: {ownImage, DateDetail, FullHeart, EmptyHeart},
	mixins: [autoRefreshRequestMixin, dateProcessMixin, permessionCheckMixin, hasLikedMixin],
	mounted() {
		this.autoRefreshRequest({
			url: 'api/album/',
			method: "GET",
			params: {
				date: this.date
			}
		}).then(value => {
			this.ownDate = value.data[0]
			this.startRender = true
		}).catch(err => {
			this.$message.error(err)
		})
	},
}
</script>

<style scoped>
.img-container {
	display: flex;
}

.img-container.left.four-three {
	padding-right: 1px;
	width: 60%;
	/*overflow: auto;*/
}

.img-container.right.four-three {
	width: 40%;
	flex-direction: column;
}

.img-container.left {
	padding-right: 1px;
}

.img-container.right {
	padding-left: 1px;
}

.album-container {
	display: flex;
	/*max-width: 800px;*/
	/*max-height: 700px;*/
	/*min-width: 400px;*/
	/*min-height: 600px;*/
	width: 700px;
	height: 700px;
	align-items: stretch
	/*width: 50%;*/
	/*padding-bottom:100%;*/
	/*height:0;*/
}

.album-container.one {
	display: block;
}

.img-container.one {
	display: block;
}

.img-one {
	display: flex;
	max-height: 700px;
}

.date-card {
	border: 0.01px solid #eee;
	/*max-width: 800px;*/
	/*!*max-height: 900px;*!*/
	/*min-width: 400px;*/
	/*min-height: 600px;*/
	border-radius: 30px;
	overflow: hidden;
}

.footer {
	padding: 20px 30px;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.time-title {
	display: flex;
	justify-content: space-between;
	max-width: 800px;
	min-width: 400px;
	padding: 10px;
}

.postscript {
	display: flex;
	margin: 15px 20px;
	line-height: 21px;
	align-items: center;
}

p {
	font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
	color: #606266;
}

h1 {
	font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
	color: #494b4d;
	padding-left: 15px;
}

h3 {
	font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
	color: #787c81;
	padding-right: 30px;
}

.el-icon-more {
	font-size: 20px;
}

.file_number {
	display: flex;
	width: 150px;
	justify-content: space-around;
}

@media screen and (max-width: 480px) {
	.main-container {
		width: 350px;
	}

	.album-container {
		display: flex;
		width: 350px;
		height: 350px;
	}

	.date-card {
		width: 350px;
		overflow: hidden;
	}

	.time-title {
		min-width: 200px;
		padding: 3px;
	}

	h1 {
		font-size: 20px;
	}

	h3 {
		font-size: 15px;
		padding-right: 10px;
	}

	p {
		font-size: 13px;
	}

	.el-icon-more {
		font-size: 15px;
	}

	.file_number {
		display: flex;
		width: 120px;
		justify-content: space-around;
	}

	.postscript {
		margin: 5px 10px;
	}

	.footer {
		padding: 10px 15px;
	}
}
</style>