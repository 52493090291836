import Vuex from "vuex";
import Vue from 'vue'
import autoRefreshRequest from "@/axios/autoRefreshRequest";
import decodeTokenPayload from "@/JWT/decodeTokenPayload";

Vue.use(Vuex)
// 该文件用于创建VueX中最为核心的store
// 准备actions，用于响应组件内的动作
const actions = {
	refreshAlbum(context) {
		autoRefreshRequest({
			url: 'api/albumdate/',
			method: 'GET',
			params: {
				ordering: "-date"
			}
		}).then((value) => {
			context.commit("GENERATE_ALBUM", value.data)
		}).catch((err) => {
			this._vm.$message.error(err)
		})
	},
	refreshUser(context) {
		autoRefreshRequest({
			url: 'api/user/',
			method: 'GET',
		}).then(value => {
			context.commit("GENERATE_USER", value.data)
		}).catch((err) => {
			this._vm.$message.error(err)
		})
	}
}
// 准备mutations，用于操作数据
const mutations = {
	GENERATE_ALBUM(state, value) {
		state.dateList = []
		for (const valueElement of value) {
			if (valueElement.files.length === 0) {
				continue
			}
			state.dateList.push(valueElement.date)
		}
	},
	GENERATE_USER(state, value) {
		state.currentUser = {}
		state.userExchange = {
			name_profile: {},
			profile_name: {}
		}
		for (let item of value) {
			this._vm.$set(state.userExchange.name_profile, item.username, item.userprofile)
			this._vm.$set(state.userExchange.profile_name, item.userprofile, item.username)
		}
		let username = decodeTokenPayload().authPayload.username
		state.currentUser = {
			username,
			userprofile: state.userExchange.name_profile[username]
		}
	},
	CLEAR_ALL(state) {
		// 清除所有vuex数据,注销用
		state.dateList = []
		state.currentUser = {}
		state.userExchange = {}
	},
}

// 准备state，用于存储数据
const state = {
	dateList: [],
	currentUser: {},
	userExchange: {
		name_profile: {},
		profile_name: {}
	}
}

//创建并导出store
export default new Vuex.Store({
	actions,
	mutations,
	state
})