<template>
	<div class="detail">
		<div
			class="like-people detail-row"
			style="display: flex;align-items: center;"
		>
			<EmptyHeart v-if="!hasLiked" @click.native="changeLikeDetail"/>
			<FullHeart v-if="hasLiked" @click.native="changeLikeDetail"/>
			<p v-for="like in ownDate.likes" :key="like.id" style="margin-left: 5px">
				{{ like.like_user.userprofile }}
			</p>
		</div>
		<div class="comments">
			<div
				class="comment detail-row"
				v-for="comment in ownDate.comments" :key="comment.id"
				style="display: flex"
			>
				<div class="content">
					<div class="list-one" style="display: flex">
						<el-link type="primary">
							<a
								style="font-weight:bold;"
								@click="addAnswerUser(comment.from_user)"
							>{{ comment.from_user.userprofile }}</a>
						</el-link>
						<el-link :underline="false">
							<p v-if="comment.to_user" style="font-weight:bold;">回复:</p>
						</el-link>
						<el-link type="primary">
							<a
								v-if="comment.to_user"
								style="font-weight:bold;"
								@click="addAnswerUser(comment.to_user)"
							>{{ comment.to_user.userprofile }}</a>
						</el-link>
					</div>
					<div class="list-two" style="margin:10px 0 10px 0">
						<p style="font-size: 18px">{{ comment.content }}</p>
					</div>
					<div class="list-three" style="display: flex">
						<el-link type="info" :underline="false">
							<p style="font-size: 12px;">
								{{ comment.upload_date.slice(0, 10) }} {{ comment.upload_date.slice(11, 19) }}
							</p>
						</el-link>
						<el-link type="info" style="margin-left: 10px" @click="deleteComment(comment)">
							<p style="font-size: 12px;" v-if="permissionCheck(comment.from_user)">删除</p>
						</el-link>
					</div>
				</div>
			</div>
		</div>
		<div class="addComments" style="margin: 10px 10px 10px 20px; display: flex;justify-content: flex-end">
			<el-input
				:placeholder="addComment.placeholder"
				v-model="addComment.content"
				class="input-with-select"
				ref="comment_input"
				@keyup.enter.native="sendComment"
			>
				<i slot="suffix" class="el-input__icon el-icon-s-promotion" @click="sendComment"></i>
				<i slot="suffix" class="el-input__icon el-icon-circle-close" @click="backDefault"></i>
			</el-input>
		</div>
	</div>
</template>

<script>
import {mapState} from "vuex";
import EmptyHeart from "@/components/EmptyHeart";
import FullHeart from "@/components/FullHeart";
import autoRefreshRequestMixin from "@/mixins/autoRefreshRequestMixin";
import permessionCheckMixin from "@/mixins/permessionCheckMixin";
import hasLikedMixin from "@/mixins/hasLikedMixin";

export default {
	name: "DateDetail",
	data() {
		return {
			addComment: {
				from_user: {
					username: "",
					userprofile: ""
				},
				content: '',
				to_user: {
					username: '',
					userprofile: '',
				},
				placeholder: '添加评论'
			},
			commentIsDeleting: false,
		}
	},
	props: ['ownDate'],
	computed: {
		...mapState(['userExchange', "currentUser"]),
	},
	methods: {
		addAnswerUser(user) {
			// 点击用户名称添加回复功能
			this.$refs.comment_input.focus()
			this.addComment.to_user.username = user.username
			this.addComment.to_user.userprofile = user.userprofile
			this.addComment.placeholder = `回复: ${user.userprofile}`
		},
		backDefault() {
			// 评论输入框失去焦点后初始化
			this.addComment.to_user = {}
			this.addComment.placeholder = '添加评论'
			this.addComment.content = ''
		},
		sendComment() {
			if (!this.addComment.content) return
			// 先复制一份addComment内容,然后清空输入框,实现防抖
			let addComment = {...this.addComment}
			this.backDefault()
			this.$emit('send_comment',addComment)
		},
		deleteComment(comment) {
			this.$emit('delete_comment',comment)
		},
		changeLikeDetail(){
			this.$emit('change_like')
		}
	},
	mixins: [autoRefreshRequestMixin, permessionCheckMixin,hasLikedMixin],
	components: {FullHeart, EmptyHeart}
}
</script>

<style scoped>
.detail {
	margin: 0 20px 10px 20px;
	padding: 10px 20px 20px 20px;
	border-radius: 30px;
	background-color: #fafafa;
}

.detail-row {
	padding: 10px 20px;
}

.like-people {
	border-bottom: 1px dashed #e6e6e6;
}

.detail-row.comment {
	border-bottom: 1px dashed #e6e6e6;
}

p {
	font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
	color: #606266;
	font-size: 15px;
}

h1 {
	font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
	color: #494b4d;
	padding-left: 15px;
}

h3 {
	font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
	color: #787c81;
	padding-right: 30px;
}

.addComments {
	/*flex-direction: column;*/
	/*justify-content: flex-start;*/
	/*align-items: flex-end;*/
	margin: 10px 10px 10px 20px;
	display: flex;
	/*justify-content: flex-end*/
}
</style>