<template>
	<div class="main-container" v-if="startRender">
		<div class="left-container">
			<div class="img-container">
				<div class="time-title" style="">
					<h2>{{ childAge }}</h2>
					<h4 style="margin: 10px">{{ dateFormat }}</h4>
				</div>
				<own-image
					v-for="(file,index) in ownDate.files"
					:key="file.id"
					:src="file.preview_path"
					:preview-src-list="positionOriPath(index)"
					lazy
					:z-index="80"
					:id="file.id"
					v-on:delete="deleteImg"
					:ref="file.id"
				>
				</own-image>
			</div>
		</div>
		<div class="right-container">
			<div class="postscripts">
				<div
					class="postscript"
					v-for="postscript in ownDate.postscripts"
					:key="postscript.id"
					v-if="postscript.content!==''"
				>
					<!--					<div v-if="postscript.content!==''">-->
					<i
						class="el-icon-paperclip" style="margin-right: 3px;font-size: 15px"
						v-if="!permissionCheck(postscript.upload_user)"></i>
					<i
						class="el-icon-edit-outline"
						v-if="permissionCheck(postscript.upload_user)"
						style="margin-right: 3px;font-size: 15px"
						@click="beginChangePostScript(postscript)"></i>
					<p v-if="!postscript['isChanging']" style="color: #606266">{{ postscript.upload_user.userprofile }}:</p>
					<p v-if="!postscript['isChanging']">{{ postscript.content }}</p>
					<el-input
						v-if="postscript['isChanging']"
						size="small"
						placeholder="请输入修改内容"
						v-model="changedPostScriptContent"
						:ref="`postscript.${postscript.id}`"
						@keyup.enter.native="changePostScript(postscript)"
					>
						<i slot="suffix" class="el-input__icon el-icon-check" @click="changePostScript(postscript)"></i>
						<i slot="suffix" class="el-input__icon el-icon-close" @click="abortChangePostScript(postscript)"></i>
						<i slot="suffix" class="el-input__icon el-icon-document-delete" @click="deletePostScript(postscript)"></i>
					</el-input>
					<!--					</div>-->
				</div>
			</div>
			<DateDetail
				:ownDate="ownDate"
				v-on:change_like="changeLike"
				v-on:send_comment="sendComment"
				v-on:delete_comment="deleteComment"/>
		</div>
	</div>
</template>

<script>
import autoRefreshRequestMixin from "@/mixins/autoRefreshRequestMixin";
import ownImage from "@/ownElement/src/ownImage";
import DateDetail from "@/components/DateDetail";
import permessionCheckMixin from "@/mixins/permessionCheckMixin";
import {mapState} from "vuex";
import dateProcessMixin from "@/mixins/dateProcessMixin";
import autoRefreshRequest from "@/axios/autoRefreshRequest";

export default {
	name: "DetailPage",
	props: ['date'],
	data() {
		return {
			ownDate: {},
			startRender: false,
			likeIsChanging: false,
			commentIsDeleting: false,
			changedPostScriptContent: ''
		}
	},
	methods: {
		positionOriPath(index) {
			return [...this.oriPathList.slice(index), ...this.oriPathList.slice(0, index)]
		},
		/*DetailPage的发送评论与点赞与DateCard的类似，
		但是为了DetailPage被关闭以后还能够在原页面不刷新看到变化，
		就额外触发了MainPage发送的emit，由MainPage对DateCard进行通讯，将修改后的数据同步到DateCard*/
		changeLike() {
			if (this.likeIsChanging) return;
			for (const index in this.ownDate.likes) {
				if (this.ownDate.likes[index].like_user.username === this.currentUser.username) {
					this.likeIsChanging = true
					// 如果相等,说明已经点赞,进入取消逻辑
					this.autoRefreshRequest({
						url: `api/thumbup/${this.ownDate.likes[index].id}/`,
						method: "DELETE"
					}).then(() => {
						this.likeIsChanging = false
						// 改变自己detailPage内的ownDate
						this.ownDate.likes.splice(index, 1)
						// 发送消息给MainPage改变DateCard内的ownDate
						this.$emit('delete_date_card_like', index)
						// this.$forceUpdate()
					}).catch((err) => {
						this.$message.error(err)
						this.likeIsChanging = false
					})
					return
				}
			}
			// 如果循环结束函数没有返回,则说明未在likes列表中,进入添加逻辑
			this.likeIsChanging = true
			this.autoRefreshRequest({
				url: 'api/thumbup/',
				method: "POST",
				data: {
					date: this.ownDate.date,
					like_user: this.currentUser.username
				}
			}).then(value => {
				let likeObj = {
					id: value.data.id,
					like_user: {
						username: value.data.like_user,
						userprofile: this.userExchange.name_profile[value.data.like_user]
					}
				}
				this.ownDate.likes.push(likeObj)
				this.$emit('add_date_card_like', likeObj)
				this.likeIsChanging = false
			}).catch((err) => {
				this.$message.error(err)
				this.likeIsChanging = false
			})
		},
		sendComment(comment) {
			this.autoRefreshRequest({
				url: 'api/comment/',
				method: "POST",
				data: {
					"from_user": this.currentUser.username,
					"content": comment.content,
					"to_user": comment.to_user.username,
					"date": this.ownDate.date
				}
			}).then(value => {
				value.data.from_user = {
					username: value.data.from_user,
					userprofile: this.userExchange.name_profile[value.data.from_user]
				}
				if (value.data.to_user) {
					value.data.to_user = {
						username: value.data.to_user,
						userprofile: this.userExchange.name_profile[value.data.to_user]
					}
				}
				this.ownDate.comments.push(value.data)
				this.$emit('add_date_card_comment', value.data)
			}, error => {
				this.$message.error(error)
			})
		},
		deleteComment(comment) {
			if (this.commentIsDeleting === true) return;
			this.commentIsDeleting = true
			if (!this.permissionCheck(comment.from_user)) {
				this.$message.error('权限不足')
				return
			}
			this.autoRefreshRequest({
				url: `api/comment/${comment.id}/`,
				method: "DELETE"
			}).then(() => {
				// 删除本地数据,实现页面动态刷新
				for (let i = 0; i < this.ownDate.comments.length; i++) {
					if (this.ownDate.comments[i].id === comment.id) {
						this.ownDate.comments.splice(i, 1)
					}
				}
				// 删除DateCard的数据
				this.$emit('delete_date_card_comment', comment.id)
				this.commentIsDeleting = false
			}).catch((err) => {
				this.commentIsDeleting = false
				this.$message.error(err)
			})
		},
		deleteImg(id) {
			let index
			for (let i = 0; i < this.ownDate.files.length; i++) {
				if (this.ownDate.files[i].id === id) {
					if (!this.permissionCheck(this.ownDate.files[i].upload_user)) {
						this.$message.error('只有发布者可以删除这张图片!')
						return
					} else {
						index = i
						break
					}
				}
			}
			autoRefreshRequest({
				url: `api/file/${id}`,
				method: "DELETE"
			}).then(() => {
				// 请求成功后删除ownDate的file
				// 调用对应Image组件的preview组件的hide方法,先将其关闭再修改页面数据,不然会卡住
				this.$refs[id][0].$refs.previewer.hide()
				this.$nextTick(() => {
					this.ownDate.files.splice(index, 1)
					this.$emit('delete_date_card_img', id)
				})
			}).catch((err) => {
				this.$message.error(err)
			})
		},
		beginChangePostScript(postscript) {
			if (this.permissionCheck(postscript.upload_user)) {
				this.$set(postscript, 'isChanging', true)
				this.$nextTick(() => {
					this.$refs[`postscript.${postscript.id}`][0].focus()
				})
			} else {
				return
			}
		},
		abortChangePostScript(postscript) {
			this.$set(postscript, 'isChanging', false)
			this.changedPostScriptContent = ''
		},
		changePostScript(postscript) {
			if (this.changedPostScriptContent === '') return
			if (this.permissionCheck(postscript.upload_user)) {
				let postscriptContent = this.changedPostScriptContent
				this.abortChangePostScript(postscript)
				this.autoRefreshRequest({
					url: `api/postscript/${postscript.id}/`,
					data: {
						content: postscriptContent
					},
					method: 'PATCH'
				}).then(() => {
					postscript.content = postscriptContent
					this.$emit('change_date_card_postscript', postscript)
				}).catch((err) => {
					this.$message.error(err)
				})
			} else {
				this.$message.error('只有发布者可以修改这条附言')
			}
		},
		deletePostScript(postscript) {
			this.$messagebox.confirm('是否删除这条附言?', '删除', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.autoRefreshRequest({
					url: `/api/postscript/${postscript.id}/`,
					method: 'DELETE'
				}).then(() => {
					postscript.content = ''
					this.$emit('delete_date_card_postscript',postscript)
					this.$message.success('删除成功')
				}).catch((err) => {
					this.$message.error(err)
				})
			}).catch((err) => {
				console.log(err)
				//	pass
			})
		}
	},
	computed: {
		...mapState(['userExchange', "currentUser"]),
		oriPathList() {
			// 原图路径，预览图用
			return this.ownDate.files.map((i) => {
				return i.ori_path
			})
		},
	},
	mixins: [autoRefreshRequestMixin, permessionCheckMixin, dateProcessMixin],
	components: {ownImage, DateDetail},
	mounted() {
		this.autoRefreshRequest({
			url: 'api/album/',
			method: "GET",
			params: {
				date: this.date
			}
		}).then(value => {
			this.ownDate = value.data[0]
			this.startRender = true
		}).catch(err => {
			this.$message.error(err)
		})
	},
}
</script>

<style scoped>
.el-image {
	width: 150px;
	height: 150px;
	margin: 3px;
	border-radius: 5px;
}

.img-container {
	/*width: 60%;*/
	max-width: 630px;
	min-width: 400px;
}

.main-container {
	display: flex;
	margin-top: 60px;
}

.right-container {
	width: 370px;
	/*max-width: 500px;*/
	/*min-width: 300px;*/
}

.postscript {
	display: flex;
	margin: 15px 20px;
	line-height: 21px;
	align-items: center;
}

.postscripts {
	padding-left: 20px;
	margin-bottom: 10px;
	border-bottom: 1px solid #eeeeee;
}

.time-title {
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
}

h2 {
	font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
	color: #606266;
}

h4 {
	font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
	color: #909399;
}

.el-dialog__header {
	padding: 0;
}

.el-icon-s-promotion {
	position: absolute;
}

@media screen and (max-width: 480px) {
	.postscript{
		margin: 5px 0;
	}
	.main-container {
		display: flex;
		margin-top: 54px;
		flex-direction: column
	}

	.el-image {
		width: 85px;
		height: 85px;
		margin: 1px;
		border-radius: 3px;
	}

	.time-title {
		width: 100%;
	}

	.img-container {
		width: 350px;
		min-width: 200px;
		max-width: 500px;
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		padding: 0 3px;
		/*justify-content: center;*/
	}

	.left-container {
		display: flex;
		justify-content: center
	}

	.right-container {
		margin-top: 5px;
	}

	.main-container {
		margin: 0;
	}
}
</style>