export default function (tokenObj) {
	if ((tokenObj.refreshPayload === undefined) || (tokenObj.refreshPayload.exp - parseInt(+new Date() / 1000) < 300)) {
		return {
			// 如果refresh不存在,或者refresh剩余时间小于300s,返回双false,跳转登录
			refreshPayload: false,
			authPayload: false
		}
	} else if ((tokenObj.authPayload === undefined) || tokenObj.authPayload.exp - parseInt(+new Date() / 1000) < 30) {
		return {
			// 如果auth令牌不存在，或剩余时间小于30s, 返回true, false, 要求刷新auth令牌
			refreshPayload: true,
			authPayload: false
		}
	} else {
		return {
			// 如果auth令牌存在，并且剩余时间大于30s，refresh令牌存在，并且剩余时间大于300s，返回全true，继续
			refreshPayload: true,
			authPayload: true
		}
	}
}