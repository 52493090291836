<template>
	<div>
		<el-backtop><i class="el-icon-caret-top"></i></el-backtop>
		<router-view></router-view>
	</div>
</template>

<script>

export default {
	name: 'App',
}
</script>

<style>
* {
	margin: 0;
	padding: 0;
}
</style>
