<template>
	<!--	<el-result title="404" subTitle="抱歉，请求错误">-->
	<!--		<template slot="icon">-->
	<!--			<img alt="Vue logo" src="../../public/img/xiaonuomi404.jpg">-->
	<!--		</template>-->
	<!--		<template slot="extra">-->
	<!--			<el-button type="primary" size="medium" @click="$router.push('/')">返回</el-button>-->
	<!--		</template>-->
	<!--	</el-result>-->
	<div class="main">
		<div class="img-container">
			<img src="../../public/img/404.gif" alt="">
		</div>
		<div class="right-info">
			<h1>啊呀,出错啦!</h1>
			<h2>这里找不到小糯米</h2>
			<p>404 NOT FOUND!</p>
			<el-button type="warning" @click="$router.push('/')">返回</el-button>
		</div>
	</div>
</template>

<script>
export default {
	name: "NotFound"
}
</script>

<style scoped>
.main {
	width: 100%;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
}
.img-container{
	margin: 20px;
	border-radius: 20px;
	border: 1px white solid;
	overflow: hidden;
}
.right-info{
	display: flex;
	flex-direction: column;
	align-items: center;
}

h1 {
	font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
	color: #303133;
	margin: 10px 0;
}
h2 {
	font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
	color: #303133;
	margin: 10px 0;
}

p {
	font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
	color: #909399;
	margin: 10px 0 20px 0;
}
@media screen and (max-width: 480px) {
	.main{
		flex-direction: column;
	}
}
</style>