import moment from "moment";

export default {
	computed: {
		childAge() {
			// 计算岁数的逻辑，momoent计算有点问题，这里手动计算
			let [nowDateY, nowDateM, nowDateD] = this.ownDate.date.split('-')
			let [birthdayY, birthdayM, birthdayD] = '2020-09-10'.split('-')
			let ageY, ageM, ageD
			// 计算岁的逻辑
			if (nowDateM > birthdayM) {
				// 如果月份大于生日月份,则岁数为年份相减
				ageY = nowDateY - birthdayY
			} else if (nowDateM == birthdayM) {
				if (nowDateD >= birthdayD) {
					// 如果月份相等,但是日大于的话,也是年份相减
					ageY = nowDateY - birthdayY
				} else {
					// 如果月份相等,但是日小于的话,也是年份相减还要-1
					ageY = nowDateY - birthdayY - 1
				}
			} else {
				// 如果月份小于,则年份相减还要-1
				ageY = nowDateY - birthdayY - 1
			}
			// 计算月的逻辑
			if (nowDateM >= birthdayM) {
				// 如果月份大于, 则直接相减, 如果月份小于, 则还要进一位 + 12
				ageM = nowDateM - birthdayM
			} else {
				ageM = nowDateM - birthdayM + 12
			}
			if (nowDateD >= birthdayD) {
				// 如果日大于出生日,则月份可以直接相减
			} else {
				if (ageM == 0) {
					// 如果月份现在已经等于零,但是日期小于出生日,这里不能直接-1,会变成负数
					ageM = ageM - 1 + 12
				} else {
					// 如果日期小于出生日期,并且月数相减不等于0,则月份再-1
					ageM--
				}
			}
			// 计算日的逻辑
			let isLeaf = false
			if ((nowDateY % 4 == 0 && nowDateY % 100 != 0) || (nowDateY % 400 == 0)) {
				isLeaf = true
			}
			let month_date = {
				'1': 31,
				'2': isLeaf ? 29 : 28,
				'3': 31,
				'4': 30,
				'5': 31,
				'6': 30,
				'7': 31,
				'8': 31,
				'9': 30,
				'10': 31,
				'11': 30,
				'12': 31
			}
			if (nowDateD >= birthdayD) {
				ageD = nowDateD - birthdayD
			} else {
				if (nowDateM !== '01'){
					ageD = month_date[nowDateM - 1] + (nowDateD - birthdayD)
				}else {
					ageD = month_date[12] + (nowDateD - birthdayD)
				}
			}
			let ageStr = ageD ? ageD + '天' : ''
			if (ageM) ageStr = ageM + '个月' + ageStr
			if (ageY) ageStr = ageY + '岁' + ageStr
			return ageStr
		},
		dateFormat() {
			// 日期格式化
			return moment(this.ownDate.date, 'YYYY-MM-DD').format('YYYY年MM月DD日')
		},
	}
}