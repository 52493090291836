// 一个axios实例,有如下几个功能
// 1. 校验auth,refresh令牌
// 2. 如果auth失效,refresh令牌
// 3. 如果refresh失效,reject
// 4. 携带auth认证头


import axios from "axios";
import validateRefresh from "./validateRefresh";

const autoRefreshAxios = axios.create({
	baseURL: BASE_URL,
	timeout: 3*60*1000,
})


autoRefreshAxios.interceptors.request.use(async (config) => {
	if (IsAuth){
		// 请求拦截器添加校验与刷新逻辑
		await validateRefresh()
		let token = localStorage.getItem('auth')?localStorage.getItem('auth'):sessionStorage.getItem('auth')
		config.headers.Authorization = 'Bearer ' + token
		return config
	}else {
		return config
	}
})

export default autoRefreshAxios