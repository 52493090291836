<template>
	<div class="album-container">
		<div v-for="i in monthAlbumList" class="infinite-list-item" :key="i">
			<!--			只传日期进入DateCard,每个DateCard在挂载的时候再发送请求获取日期信息.-->
			<DateCard :date="i" :ref="i"></DateCard>
		</div>
		<div class="footer-nav">
			<div class="forword-month link" @click="forwardMonth">
				<i class="el-icon-back">
					<el-link type="primary">上个月</el-link>
				</i>
			</div>
			<div class="nav-middle">
				<i class="el-icon-loading" v-if="loadingIcon"></i>
				<!--									<p v-if="!loading">下拉加载更多</p>-->
			</div>
			<div class="next-month link" @click="nextMonth">
				<el-link type="primary">下个月</el-link>
				<i class="el-icon-right"></i>
			</div>
		</div>
	</div>
</template>

<script>
import DateCard from "@/components/DateCard";
import {mapState} from "vuex";

export default {
	name: "AlbumCom",
	props: ['monthList'],
	data() {
		return {
			routerChanging: false,
			loadingIcon: false, // ablum的加载状态指示
		}
	},
	components: {DateCard},
	computed: {
		monthAlbumList() {
			// 生成这个月的date列表,循环生成dateCard
			// 如果参数是latest,根据月份列表自动生成到最新月份的albumList
			let dateMonth
			if (this.$route.params.date === 'latest') {
				dateMonth = this.monthList[0]
			} else {
				dateMonth = this.$route.params.date
			}
			let albumList = []
			for (const dateListElement of this.dateList) {
				if (dateListElement.slice(0, 7) === dateMonth) {
					albumList.push(dateListElement)
				}
			}
			return albumList
		},
		...mapState(['dateList'])
	},
	methods: {
		nextMonth() {
			if (this.monthList.length === 1) {
				this.$message.info('已经到最后啦')
				return;
			}
			if (this.$route.params.date === 'latest') {
				this.$router.push({
					name: 'albumRoute',
					params: {
						date: this.monthList[1]
					}
				})
				return
			}
			for (let i = 0; i < this.monthList.length; i++) {
				if (this.$route.params.date === this.monthList[i]) {
					if (i === this.monthList.length - 1) {
						this.$message.info('已经到最后啦')
					} else {
						if (this.routerChanging) return
						this.routerChanging = true
						this.loadingIcon = true
						this.$router.push({
							name: 'albumRoute',
							params: {
								date: this.monthList[i + 1]
							}
						})
					}
				}
			}
			setTimeout(() => {
				// 1000ms后flag置为false,才能再次触发
				this.routerChanging = false
				// console.log('flag changeback')
			}, 2000)
			this.$nextTick(() => {
				// 页面刷新后将loading状态指示置为false
				this.loadingIcon = false
			})
		},
		forwardMonth() {
			if (this.$route.params.date === 'latest') {
				this.$message.info('已经到最前啦')
				return
			}
			if (this.monthList.length === 1) {
				this.$message.info('已经到最前啦')
				return;
			}
			for (let i = 0; i < this.monthList.length; i++) {
				if (this.$route.params.date === this.monthList[i]) {
					if (i === 0) {
						this.$message.info('已经到最前啦')
					} else {
						if (this.routerChanging) return
						this.routerChanging = true
						this.loadingIcon = true
						this.$router.push({
							name: 'albumRoute',
							params: {
								date: this.monthList[i - 1]
							}
						})
					}
				}
			}
			setTimeout(() => {
				// 1000ms后flag置为false,才能再次触发
				this.routerChanging = false
				// console.log('flag changeback')
			}, 2000)
			this.$nextTick(() => {
				// 页面刷新后将loading状态指示置为false
				this.loadingIcon = false
			})
		},
	},
	mounted() {
		if (this.monthList.indexOf(this.$route.params.date) === -1 && this.$route.params.date !== 'latest') {
			this.$router.push({
				name: 'notFound'
			})
		}
		setTimeout(() => {
			window.a = this.$refs['2022-05-22']
		}, 2000)
	},
}
</script>

<style scoped>
.footer-nav {
	display: flex;
	justify-content: space-between;
	margin: 20px 0;
}

p {
	font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
}

.link {
	font-size: 16px;
	color: #409EFF;
}

.nav-middle {
	font-size: 13px;
}

@media screen and (max-width: 480px) {
	.album-container {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.footer-nav {
		width: 100%;
	}
}
</style>